<template>
  <v-container fluid>
    <v-card flat class="custom-grey-border remove-border-radius">
      <v-card-title class="headline grey lighten-4">
        <h3 class="font-weight-700 custom-headline color-custom-blue">
          Payment Information
        </h3>
      </v-card-title>
      <v-card-text class="p-6 font-size-16">
        <v-row>
          <v-col md="4" cols="12">
            <label class="font-size-16">Amount Pending</label>
            <v-text-field
              dense
              color="cyan"
              filled
              type="number"
              min="0"
              readonly
              :disabled="pageLoading"
              v-model.trim="paymentInformation.pending"
              label="Amount Pending"
              :rules="[
                validateRules.minLength(
                  paymentInformation.pending,
                  'Amount Pending',
                  1
                ),
                validateRules.maxLength(
                  paymentInformation.pending,
                  'Amount Pending',
                  100
                )
              ]"
              solo
              flat
              hide-details
            ></v-text-field>
          </v-col>
          <v-col md="4" cols="12">
            <label class="font-size-16">Amount Received</label>
            <v-text-field
              dense
              color="cyan"
              filled
              type="number"
              min="0"
              :disabled="pageLoading"
              v-model.trim="paymentInformation.received"
              label="Amount Received"
              :rules="[
                validateRules.minLength(
                  paymentInformation.received,
                  'Amount Received',
                  1
                ),
                validateRules.maxLength(
                  paymentInformation.received,
                  'Amount Received',
                  100
                )
              ]"
              solo
              flat
              hide-details
              v-on:keypress="limitDecimal($event, paymentInformation.received)"
            ></v-text-field>
          </v-col>
          <v-col md="4" cols="12">
            <label class="font-size-16">Transaction ID</label>
            <v-text-field
              dense
              color="cyan"
              filled
              :disabled="pageLoading"
              v-model.trim="paymentInformation.transaction_id"
              label="Transaction ID"
              :rules="[
                validateRules.minLength(
                  paymentInformation.transaction_id,
                  'Transaction ID',
                  1
                ),
                validateRules.maxLength(
                  paymentInformation.transaction_id,
                  'Transaction ID',
                  100
                )
              ]"
              solo
              flat
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { PaymentEventBus } from "@/core/lib/payment/payment.lib";
import ValidationMixin from "@/core/plugins/validation-mixin";

export default {
  name: "payment-information",
  mixins: [ValidationMixin],
  data() {
    return {
      timeoutLimit: 500,
      timeout: null,
      paymentInformation: {
        pending: "0.00",
        received: "0.00",
        transaction_id: null
      }
    };
  },
  props: {
    pageLoading: {
      type: Boolean,
      required: true,
      default: false
    },
    updateData: {
      type: Object,
      default: () => {
        return new Object();
      }
    }
  },
  watch: {
    paymentInformation: {
      deep: true,
      immediate: true,
      handler() {
        const _this = this;
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function() {
          PaymentEventBus.$emit(
            "update:payment-information",
            _this.paymentInformation
          );
        }, _this.timeoutLimit);
      }
    },
    updateData: {
      deep: true,
      handler() {
        this.paymentInformation = {
          pending: this.updateData.pending || "0.00",
          received: this.updateData.amount || "0.00",
          transaction_id: this.updateData.transaction_id || null
        };
      }
    }
  }
};
</script>
